import {
  Image,
  SimpleGrid,
  Container,
  Heading,
  Card,
  CardBody,
  Text,
  Flex,
  Box,
} from "@chakra-ui/react";

import ImgWebDevelopment from "../images/web.jpeg"
import ImgDigitalMarketing from "../images/seo.jpeg"
import ImgClinicalResearch from "../images/clinicial_research.jpeg"
import ImgDigitalScience from "../images/digital_science.jpeg"
import ImgSoftwareTesting from "../images/testing.jpg"
import ImgJava from "../images/java.jpeg"

import ImgPharmacovigilance from "../images/Pharmacovigilance.jpeg"
import ImgMedicalCoding from "../images/medical_code.jpeg"
import ImgCDM from "../images/clinical_data_mgmt.jpeg"
import ImgRegularAffair from "../images/affairs.jpeg"
import ImgMedicalScribe from "../images/scribe.jpeg"

import { useRef } from "react";
import { useInView } from "framer-motion";

const ServicePage = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const ref1 = useRef(null);
  const isInView1 = useInView(ref1, { once: true });
  return (
    <Container
      maxW="container.xl"
      pt="4rem"
      id="services"
      justifyContent="center"
      px={8}
      css={{ scrollMargin: "4rem" }}
      ref={ref}
    >
      <Heading
        as="h1"
        size="3xl"
        noOfLines={4}
        bgGradient="linear(to-r, #7928CA, #FB3640)"
        bgClip="text"
        textAlign="center"
        pb={10}
        style={{
          transform: isInView ? "none" : "translateY(100px)",
          opacity: isInView ? 1 : 0,
          transition: "all .6s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
        }}
      >
        Courses
      </Heading>
      <SimpleGrid
        columns={{ base: 1, md: 2 }}
        spacing={10}
        data-cues="slideInUp"
        ref={ref1}
      >
      
      <Card
          p={5}
          boxShadow="md"
          style={{
            transform: isInView1 ? "none" : "translateX(-200px)",
            opacity: isInView1 ? 1 : 0,
            transition: "all .6s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          }}
        >
          <Heading py={5} as="h2" size="md" noOfLines={2} textAlign="center">
          Clinical Research
          </Heading>
          <CardBody>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              direction={["column", "column", "row", "row"]}
              gap={10}
            >
              <Box flexBasis={["100%", "100%", "40%", "40%"]}>
                <Image src={ImgClinicalResearch} alt="Clinofytechnology Team" />
              </Box>
              <Box flexBasis={["100%", "100%", "60%", "60%"]}>
                <Text color="gray.500">
                we are offering a comprehensive selection of courses such as CRC, CRA, CDM, Medical Coding, 
                Drug Safety Associate, and more. Our institute is your pathway to a rewarding career in clinical research. 
                </Text>
              </Box>
            </Flex>
          </CardBody>
        </Card>
      
        
        <Card
          p={5}
          boxShadow="md"
          style={{
            transform: isInView1 ? "none" : "translateX(-200px)",
            opacity: isInView1 ? 1 : 0,
            transition: "all .6s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          }}
        >
          <Heading py={5} as="h2" size="md" noOfLines={2} textAlign="center">
          Pharmacovigilance 
          </Heading>
          <CardBody>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              direction={["column", "column", "row", "row"]}
              gap={10}
            >
              <Box flexBasis={["100%", "100%", "40%", "40%"]}>
                <Image src={ImgPharmacovigilance} alt="Clinofytechnology Team" />
              </Box>
              <Box flexBasis={["100%", "100%", "60%", "60%"]}>
                <Text color="gray.500">
                A pharmacovigilance course is a specialised program that educates individuals in the field of pharmacovigilance, which involves the detection, assessment, 
                understanding, and prevention of adverse effects or any other drug-related problems
                </Text>
              </Box>
            </Flex>
          </CardBody>
        </Card>
      
        <Card
          p={5}
          boxShadow="md"
          style={{
            transform: isInView1 ? "none" : "translateX(-200px)",
            opacity: isInView1 ? 1 : 0,
            transition: "all .6s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          }}
        >
          <Heading py={5} as="h2" size="md" noOfLines={2} textAlign="center">
          Medical Coding 
          </Heading>
          <CardBody>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              direction={["column", "column", "row", "row"]}
              gap={10}
            >
              <Box flexBasis={["100%", "100%", "40%", "40%"]}>
                <Image src={ImgMedicalCoding} alt="Clinofytechnology Team" />
              </Box>
              <Box flexBasis={["100%", "100%", "60%", "60%"]}>
                <Text color="gray.500">
                  Transform the way you manage your healthcare with our
                  cutting-edge software. Streamline operations, improve patient
                  outcomes, and enhance the overall experience. Say goodbye to
                  manual processes and hello to a smarter, more efficient
                  healthcare system.
                </Text>
              </Box>
            </Flex>
          </CardBody>
        </Card>
        
  
        <Card
          p={5}
          boxShadow="md"
          style={{
            transform: isInView1 ? "none" : "translateX(-200px)",
            opacity: isInView1 ? 1 : 0,
            transition: "all .6s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          }}
        >
          <Heading py={5} as="h2" size="md" noOfLines={2} textAlign="center">
          Clinical Data Management 
          </Heading>
          <CardBody>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              direction={["column", "column", "row", "row"]}
              gap={10}
            >
              <Box flexBasis={["100%", "100%", "40%", "40%"]}>
                <Image src={ImgCDM} alt="Clinofytechnology Team" />
              </Box>
              <Box flexBasis={["100%", "100%", "60%", "60%"]}>
                <Text color="gray.500">
                Clinical data management (CDM) is a vital component of clinical research, aimed at producing dependable, high-quality data from clinical trials. CDM involves collecting,
                 integrating, and making data available at the right quality and cost.
                </Text>
              </Box>
            </Flex>
          </CardBody>
        </Card>
      
        <Card
          p={5}
          boxShadow="md"
          style={{
            transform: isInView1 ? "none" : "translateX(-200px)",
            opacity: isInView1 ? 1 : 0,
            transition: "all .6s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          }}
        >
          <Heading py={5} as="h2" size="md" noOfLines={2} textAlign="center">
          Regulatory Affairs 
          </Heading>
          <CardBody>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              direction={["column", "column", "row", "row"]}
              gap={10}
            >
              <Box flexBasis={["100%", "100%", "40%", "40%"]}>
                <Image src={ImgRegularAffair} alt="Clinofytechnology Team" />
              </Box>
              <Box flexBasis={["100%", "100%", "60%", "60%"]}>
                <Text color="gray.500">
                A Regulatory Affairs course is an educational program that caters to both novices and
                 industry professionals who want to understand the basics of regulatory affairs. The course covers fundamental concepts related to regulations in different countries and aims to enhance participants' knowledge and skills in the field of regulatory affairs
                </Text>
              </Box>
            </Flex>
          </CardBody>
        </Card>

        <Card
          p={5}
          boxShadow="md"
          style={{
            transform: isInView1 ? "none" : "translateX(-200px)",
            opacity: isInView1 ? 1 : 0,
            transition: "all .6s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          }}
        >
          <Heading py={5} as="h2" size="md" noOfLines={2} textAlign="center">
          Medical Scribe 
          </Heading>
          <CardBody>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              direction={["column", "column", "row", "row"]}
              gap={10}
            >
              <Box flexBasis={["100%", "100%", "40%", "40%"]}>
                <Image src={ImgMedicalScribe} alt="Clinofytechnology Team" />
              </Box>
              <Box flexBasis={["100%", "100%", "60%", "60%"]}>
                <Text color="gray.500">
                Medical scribes help physicians and other healthcare providers with documentation tasks, including taking patient histories, recording medical exams, entering data into electronic health records (EHRs), 
                and managing medical charts.
                </Text>
              </Box>
            </Flex>
          </CardBody>
        </Card>

    
        <Card
          p={5}
          boxShadow="md"
          style={{
            transform: isInView1 ? "none" : "translateX(-200px)",
            opacity: isInView1 ? 1 : 0,
            transition: "all .6s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          }}
        >
          <Heading py={5} as="h2" size="md" noOfLines={2} textAlign="center">
          Digital Science 
          </Heading>
          <CardBody>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              direction={["column", "column", "row", "row"]}
              gap={10}
            >
              <Box flexBasis={["100%", "100%", "40%", "40%"]}>
                <Image src={ImgDigitalScience} alt="Clinofytechnology Team" />
              </Box>
              <Box flexBasis={["100%", "100%", "60%", "60%"]}>
                <Text color="gray.500">
                  Transform the way you manage your healthcare with our
                  cutting-edge software. Streamline operations, improve patient
                  outcomes, and enhance the overall experience. Say goodbye to
                  manual processes and hello to a smarter, more efficient
                  healthcare system.
                </Text>
              </Box>
            </Flex>
          </CardBody>
        </Card>
        <Card
          p={5}
          boxShadow="md"
          style={{
            transform: isInView1 ? "none" : "translateX(200px)",
            opacity: isInView1 ? 1 : 0,
            transition: "all .6s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          }}
        >
          <Heading py={5} as="h2" size="md" noOfLines={2} textAlign="center">
            Digital Marketing (SEO)
          </Heading>
          <CardBody>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              direction={["column", "column", "row", "row"]}
              gap={10}
            >
              <Box flexBasis={["100%", "100%", "40%", "40%"]}>
                <Image src={ImgDigitalMarketing} alt="Clinofytechnology Team" />
              </Box>
              <Box flexBasis={["100%", "100%", "60%", "60%"]}>
                <Text color="gray.500">
               We are offering comprehensive Digital Marketing courses. In today's digital age, Google Ads and SEO have become crucial in every aspect, from shopping to healthcare. This field is booming, providing countless career opportunities for digital marketers. If you want to learn modern marketing from the world's best faculty, 
                connect with global business leaders, and apply these skills to your business.
                </Text>
              </Box>
            </Flex>
          </CardBody>
        </Card>

        <Card
          p={5}
          boxShadow="md"
          style={{
            transform: isInView1 ? "none" : "translateX(-200px)",
            opacity: isInView1 ? 1 : 0,
            transition: "all .6s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          }}
        >
          <Heading py={5} as="h2" size="md" noOfLines={2} textAlign="center">
          Java 
          </Heading>
          <CardBody>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              direction={["column", "column", "row", "row"]}
              gap={10}
            >
              <Box flexBasis={["100%", "100%", "40%", "40%"]}>
                <Image src={ImgJava} alt="Clinofytechnology Team" />
              </Box>
              <Box flexBasis={["100%", "100%", "60%", "60%"]}>
                <Text color="gray.500">
                We are offering a highly sought-after course in the modern tech field – Java training. If you're passionate about tackling complex computing and programming challenges, 
                our Java training can pave the way for unparalleled success in the tech industry.
                </Text>
              </Box>
            </Flex>
          </CardBody>
        </Card>
        <Card
          p={5}
          boxShadow="md"
          style={{
            transform: isInView1 ? "none" : "translateX(200px)",
            opacity: isInView1 ? 1 : 0,
            transition: "all .6s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          }}
        >
          <Heading py={5} as="h2" size="md" noOfLines={2} textAlign="center">
            Web Development
          </Heading>
          <CardBody>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              direction={["column", "column", "row", "row"]}
              gap={10}
            >
              <Box flexBasis={["100%", "100%", "40%", "40%"]}>
                <Image src={ImgWebDevelopment} alt="Clinofytechnology Team" />
              </Box>
              <Box flexBasis={["100%", "100%", "60%", "60%"]}>
                <Text color="gray.500">
                  Transform your vision into reality with our top-notch web
                  app development services. Experience seamless navigation,
                  cutting-edge features and superior performance. Our team of
                  experts will bring your ideas to life, delivering customized
                  solutions that meet your unique needs. Partner with us for a
                  smooth, efficient and successful app launch.
                </Text>
              </Box>
            </Flex>
          </CardBody>
        </Card>
        <Card
          p={5}
          boxShadow="md"
          style={{
            transform: isInView1 ? "none" : "translateX(-200px)",
            opacity: isInView1 ? 1 : 0,
            transition: "all .6s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          }}
        >
          <Heading py={5} as="h2" size="md" noOfLines={2} textAlign="center">
          Software Testing 
          </Heading>
          <CardBody>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              direction={["column", "column", "row", "row"]}
              gap={10}
            >
              <Box flexBasis={["100%", "100%", "40%", "40%"]}>
                <Image src={ImgSoftwareTesting} alt="Clinofytechnology Team" />
              </Box>
              <Box flexBasis={["100%", "100%", "60%", "60%"]}>
                <Text color="gray.500">
                Choosing us for your Software Testing Course ensures a transformative learning experience grounded in expertise, practical application, and industry relevance. Our course is crafted by seasoned professionals and industry leaders,
                 offering a comprehensive curriculum that covers the latest tools, methodologies, and best practices in software testing. 
                </Text>
              </Box>
            </Flex>
          </CardBody>
        </Card>
      </SimpleGrid>
    </Container>
  );
};
export default ServicePage;
