import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import { ChakraProvider, extendTheme } from "@chakra-ui/react";

const root = ReactDOM.createRoot(document.getElementById("root"));
const theme = extendTheme({
  colors: {
    black: "#1a1917",
    gray: {
      50: "#fafaf9",
      100: "#f1f1f1",
      200: "#e7e7e7",
      300: "#d4d4d3",
      400: "#adadac",
      500: "#7f7f7e",
      600: "#555453",
      700: "#373635",
      800: "#21201e",
      900: "#1a1917",
    },
    red: {
      50: "#fff5f4",
      100: "#fed7d3",
      200: "#fdb3ab",
      300: "#fc8274",
      400: "#fc614f",
      500: "#e24331",
      600: "#c03829",
      700: "#9b2e21",
      800: "#83271c",
      900: "#5f1c15",
    },
    orange: {
      50: "#fffaf2",
      100: "#feebcd",
      200: "#fdd292",
      300: "#fbac36",
      400: "#d8942f",
      500: "#ba8028",
      600: "#9d6c22",
      700: "#7d561b",
      800: "#634415",
      900: "#513711",
    },
    yellow: {
      50: "#fffef7",
      100: "#fefbce",
      200: "#fcf15c",
      300: "#f0e334",
      400: "#dbd02f",
      500: "#b4ab27",
      600: "#90891f",
      700: "#706b18",
      800: "#545012",
      900: "#45420f",
    },
    green: {
      50: "#f0fff9",
      100: "#a0fdd8",
      200: "#34f2a6",
      300: "#2ed894",
      400: "#29bd81",
      500: "#23a26f",
      600: "#1d865c",
      700: "#166848",
      800: "#12553b",
      900: "#0f4630",
    },
    teal: {
      50: "#edfdff",
      100: "#a9f5fd",
      200: "#44e9fb",
      300: "#31d0e2",
      400: "#29b1c0",
      500: "#2396a3",
      600: "#1d7a85",
      700: "#165f67",
      800: "#124f56",
      900: "#0f4147",
    },
    cyan: {
      50: "#f2fbff",
      100: "#c8effe",
      200: "#afe8fd",
      300: "#90e0fd",
      400: "#35c2f6",
      500: "#31b2e2",
      600: "#2ca1cb",
      700: "#2485a8",
      800: "#1e6d8a",
      900: "#17546b",
    },
    blue: {
      50: "#f1f6ff",
      100: "#cbdffe",
      200: "#a4c8fd",
      300: "#78adfc",
      400: "#4d93fb",
      500: "#327cea",
      600: "#2a68c4",
      700: "#205096",
      800: "#1a417b",
      900: "#163564",
    },
    purple: {
      50: "#f9f5ff",
      100: "#e8d8fe",
      200: "#d8bbfe",
      300: "#be8ffd",
      400: "#ac6ffc",
      500: "#9140fb",
      600: "#7a2fdc",
      700: "#6427b5",
      800: "#522095",
      900: "#3d186f",
    },
    pink: {
      50: "#fff5f9",
      100: "#fed6e6",
      200: "#feb4d2",
      300: "#fd83b3",
      400: "#fc5899",
      500: "#e23178",
      600: "#c22a67",
      700: "#9e2254",
      800: "#7c1b42",
      900: "#5c1431",
    },
    primary: {
      50: "#fff5f6",
      100: "#fed7d9",
      200: "#fdb2b6",
      300: "#fc8086",
      400: "#fc5e66",
      500: "#ec333c",
      600: "#c82b33",
      700: "#a12329",
      800: "#891d23",
      900: "#64161a",
    },
  },
});
root.render(
  <ChakraProvider theme={theme}>
    <App />
  </ChakraProvider>
);
