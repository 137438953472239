import {
  Image,
  SimpleGrid,
  Container,
  Heading,
  Card,
  CardBody,
  Text,
  Flex,
  Box,
  Button
} from "@chakra-ui/react";
import ImgTeam from "../images/team.svg";
import { RiArrowRightLine } from "react-icons/ri";
import { useRef } from "react";
import { useState } from "react";
import { useInView } from "framer-motion";
import ImgDigitalMarketing from "../images/digital_marketing.svg"

const AboutPage = () => {
  const [arrowPadding, setArrowPadding] = useState(2);
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const ref1 = useRef(null);
  const isInView1 = useInView(ref1, { once: true });

  return (
    <Container
      maxW="container.xl"
      pt="4rem"
      id="about"
      justifyContent="center"
      px={8}
      css={{ scrollMargin: "4rem" }}
      ref={ref}
    >
      <Heading
        as="h1"
        size="3xl"
        noOfLines={4}
        bgGradient="linear(to-r, #7928CA, #FB3640)"
        bgClip="text"
        textAlign="center"
        pb={10}
        style={{
          transform: isInView ? "none" : "translateY(100px)",
          opacity: isInView ? 1 : 0,
          transition: "all .6s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
        }}
      >
        About
      </Heading>
      <SimpleGrid spacing={10}>
        <Card
          boxShadow="md"
          style={{
            transform: isInView ? "none" : "translateY(200px)",
            opacity: isInView ? 1 : 0,
            transition: "all .6s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          }}
        >
          <CardBody>
            <Flex
              justifyContent="center"
              alignItems="center"
              direction="column"
              gap={10}
            >
              {/* <Box w="50%">
                <Image alignSelf="center" src={ImgTeam} alt="ZenSpyder Team" />
              </Box> */}
              <Box p={[0, 0, 5, 5]}>
                <Text color="gray.500" lineHeight={8}>
                  &emsp;&emsp; Welcome &nbsp;
                  <mark
                    style={{
                      background: "transparent",
                      color: "#FB3640",
                      fontWeight: "600",
                    }}
                  >
                    Clinofy Technology Services
                  </mark>
                  {"  "}&nbsp;your trusted destination for excellence in Clinical Research, market research and IT Training Placement in Pune!
                  <br />
                  <br /> &emsp;&emsp;India is emerging as a key hub for Clinical Research, with a rapidly increasing demand for professionals in the field. The surge in demand is making Clinical Research a highly promising career option with significant growth potential.
                  Get ready for an exciting career in a field poised for massive growth.
                  <br />
                  <br /> &emsp;&emsp;At <b>Clinofy Technology Services</b>, we are committed to shaping the future of healthcare and information technology by providing top-notch education and training.
                  our institute has become a beacon for aspiring professionals seeking comprehensive knowledge and hands-on training in clinical research, marketing and IT.
                  <br />
                  <br />

                  {/* <mark
                    style={{
                      background: "transparent",
                      color: "#FB3640",
                      fontWeight: "600",
                    }}
                  >
                    Our Mission
                  </mark> */}

                  <Heading
                    as="h4"
                    bgGradient="linear(to-r, #7928CA, #FB3640)"
                    bgClip="text"
                    textAlign="center"
                    pb={1}
                    style={{
                      transform: isInView ? "none" : "translateY(100px)",
                      opacity: isInView ? 1 : 0,
                      transition: "all .6s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                    }}
                  >
                   Our Mission
                  </Heading>


                  <br />
                  &emsp;&emsp;our mission is to empower individuals with the knowledge and skills they need to excel in their chosen fields like research and IT. We believe that quality education is the key to personal and professional growth, and our institute is dedicated to providing exceptional training and guidance.
                  <br />&emsp;&emsp;We have created a dynamic and supportive learning environment that fosters curiosity, innovation, and a passion for continuous improvement. Through cutting-edge curriculum, experienced instructors, and state-of-the-art facilities, we aim to equip our students with the practical expertise
                  required to meet the demands of today's competitive job market..

                  At Clinofy Technology Services, Join us on this journey of education,
                  exploration, and achievement.
                  <br /><br /><h1>Your success is our mission!</h1>

                </Text>
              </Box>
            </Flex>
          </CardBody>
        </Card>
      </SimpleGrid>
      <br />
      <SimpleGrid
        columns={{ base: 1, md: 3 }}
        spacing={10}
        data-cues="slideInUp"
        ref={ref1}
      >

        <Card
          p={5}
          boxShadow="md"
          style={{
            transform: isInView1 ? "none" : "translateX(200px)",
            opacity: isInView1 ? 1 : 0,
            transition: "all .6s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          }}
        >
          {/* <Button
                    onMouseOver={() => setArrowPadding(8)}
                    onMouseOut={() => setArrowPadding(2)}
                    w="100%"
                    style={{
                      transform: isInView ? "none" : "translateY(100px)",
                      opacity: isInView ? 1 : 0,
                      transition: "all .6s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                    }}
                    size="lg"
                  >
                    Expert Faculty
                  </Button> */}

          <Heading
            as="h4"
            bgGradient="linear(to-r, #7928CA, #FB3640)"
            bgClip="text"
            textAlign="center"
            pb={1}
            style={{
              transform: isInView ? "none" : "translateY(100px)",
              opacity: isInView ? 1 : 0,
              transition: "all .6s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
            }}
          >
            Expert Faculty
          </Heading>
          <CardBody>
            {/* <Flex
              justifyContent="space-between"
              alignItems="center"
              direction={["column", "column", "row", "row"]}
              gap={10}
            > */}
            <Box flexBasis={["100%", "100%", "60%", "60%"]}>
              <Text color="gray.500">
                Our institute boasts a team of seasoned professionals and industry experts who bring a wealth of knowledge and real-world experience to the classroom. They are dedicated to guiding and mentoring our students,
                ensuring they are well-prepared for the challenges of their respective fields.
              </Text>
            </Box>
            {/* </Flex> */}
          </CardBody>
        </Card>
        <Card
          p={5}
          boxShadow="md"
          style={{
            transform: isInView1 ? "none" : "translateX(200px)",
            opacity: isInView1 ? 1 : 0,
            transition: "all .6s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          }}
        >
          {/* <Button
                    onMouseOver={() => setArrowPadding(8)}
                    onMouseOut={() => setArrowPadding(2)}
                    w="100%"
                    colorScheme="primary"
                    size="lg"
                  >
                     Cutting-edge Curriculum
                  </Button> */}

          <Heading
            as="h4"
            bgGradient="linear(to-r, #7928CA, #FB3640)"
            bgClip="text"
            textAlign="center"
            pb={1}
            style={{
              transform: isInView ? "none" : "translateY(100px)",
              opacity: isInView ? 1 : 0,
              transition: "all .6s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
            }}
          >
            Cutting-edge Curriculum
          </Heading>
          <CardBody>
            {/* <Flex
              justifyContent="space-between"
              alignItems="center"
              direction={["column", "column", "row", "row"]}
              gap={10}
            > */}
            <Box flexBasis={["100%", "100%", "60%", "60%"]}>
              <Text color="gray.500">
                Our carefully crafted curriculum is designed to align with industry trends and demands. We constantly update our courses to incorporate the latest advancements,
                ensuring that our students graduate with a competitive edge in the job market.
              </Text>
            </Box>
            {/* </Flex> */}
          </CardBody>
        </Card>
        <Card
          p={5}
          boxShadow="md"
          style={{
            transform: isInView1 ? "none" : "translateX(200px)",
            opacity: isInView1 ? 1 : 0,
            transition: "all .6s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          }}
        >
          {/* <Button
                    onMouseOver={() => setArrowPadding(8)}
                    onMouseOut={() => setArrowPadding(2)}
                    w="100%"
                    colorScheme="primary"
                    size="lg"
                  >
                     Industry Connections
                  </Button> */}
          <Heading
            as="h4"
            bgGradient="linear(to-r, #7928CA, #FB3640)"
            bgClip="text"
            textAlign="center"
            pb={1}
            style={{
              transform: isInView ? "none" : "translateY(100px)",
              opacity: isInView ? 1 : 0,
              transition: "all .6s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
            }}
          >
            Industry Connections
          </Heading>
          <CardBody>
            {/* <Flex
              justifyContent="space-between"
              alignItems="center"
              direction={["column", "column", "row", "row"]}
              gap={10}
            > */}
            <Box flexBasis={["100%", "100%", "60%", "60%"]}>
              <Text color="gray.500">
                We understand the importance of industry connections in fostering career growth. Through strategic partnerships and collaborations, we provide our students with opportunities to engage with professionals and gain valuable insights into the real-world applications
                of their knowledge.
              </Text>
            </Box>
            {/* </Flex> */}
          </CardBody>
        </Card>
      </SimpleGrid>
    </Container>
  );
};
export default AboutPage;
